@import 'colors';

/**
 * @tokens Fonts Family
 */
$fontIconissimo: 'iconissimo';
$fontAvenirNext: 'AvenirNext';

/**
 * @tokens Fonts Size
 */
$font-xs: 0.6rem;
$font-xss: 0.8rem;
$font-s: 0.9rem;
$font-m: 1rem;
$font-ml: 1.1rem;
$font-l: 1.3rem;
$font-xl: 2rem;

/**
 * @tokens Line height Size
 */
$line-height-5: 1.6;

/**
 * @tokens Fonts Weight
 */
$font-weight-regular: 400;
$font-weight-strong: 600;
$font-weight-bold: 700;

/**
 * @tokens Spacing
 */
$spacing-1: 2px;
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 12px;
$spacing-5: 16px;
$spacing-6: 24px;
$spacing-7: 32px;
$spacing-8: 40px;
$spacing-9: 48px;

/**
 * @tokens Colors
 */
$color-secondary: $white;
$color-text: $grey-900;
$color-info: $blue-900;
$color-info-bg: $blue-100;
$color-banner: $black;
$color-banner-bg: $blue-200;
$color-error: $red-900;
$color-error-bg: $red-100;
$color-warning: $orange-900;
$color-warning-bg: $orange-100;
$color-success: $green-900;
$color-success-bg: $green-100;

/**
 * Shadows
 */
$shadow-xs-small: 1px 3px 3px 0 rgba(0, 0, 0, 0.075);
$shadow-small: inset 0 1px 1px $grey-900;

$border: $grey-300;

/**
 * New Rebranding
 */
$brand-primary: $blue-400;
$brand-secondary: $blue-700;
$brand-accent: $red-800;

/**
 * Line height
 */
$default-line-height: 1.42857143;
