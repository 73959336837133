@import '../../sass/variables/';

.loading {
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  min-height: inherit;

  &__blocks {
    display: flex;
    align-items: center;
    max-width: 80px;
    gap: 10px;
  }

  &__element {
    animation: geometric-moves 0.6s infinite ease-in-out;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    &--first {
      animation-delay: -70ms;
      background-color: $grey-400;
    }

    &--second {
      animation-delay: 0s;
      background-color: $grey-700;
    }

    &--third {
      background-color: $brand-primary;
      animation-delay: -0.14s;
    }
  }
}

@keyframes geometric-moves {
  33% {
    transform: translateY(20px);
  }
  66% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
