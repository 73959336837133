@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

$alert-icon-size: 21px;
$default-line-height: 1.42857143;

.alert {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  width: auto;
  margin-top: $spacing-5;
  margin-bottom: $spacing-5;
  padding: $spacing-5;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &--size-small {
    padding: $spacing-3;
    line-height: $default-line-height;
    margin-top: $spacing-2;
    margin-bottom: $spacing-2;
  }

  &--info {
    color: $color-info;
    background-color: $color-info-bg;
  }

  &--banner {
    color: $color-banner;
    background-color: $color-banner-bg;
  }

  &--warning {
    color: $color-warning;
    background-color: $color-warning-bg;
  }

  &--error {
    color: $color-error;
    background-color: $color-error-bg;
  }

  &--success {
    color: $color-success;
    background-color: $color-success-bg;
  }

  &__icon-wrapper {
    font-size: $alert-icon-size;
    margin-right: $spacing-3;
    align-self: flex-start;
  }
}

@import '../../sass/variables';

.alert {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  width: auto;
  margin-top: $spacing-5;
  margin-bottom: $spacing-5;
  padding: $spacing-5;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &--size-small {
    padding: $spacing-3;
    line-height: $default-line-height;
    margin-top: $spacing-2;
    margin-bottom: $spacing-2;
  }

  &--size-medium {
    margin-top: $spacing-6;
    margin-bottom: $spacing-2;
    padding: $spacing-5;
  }

  &--info {
    color: $color-info;
    background-color: $color-info-bg;

    &--showDesignVariant {
      display: flex;
      background-color: $blue-20-new-design;
      border-radius: $spacing-3;
    }
  }

  &--banner {
    color: $color-banner;
    background-color: $color-banner-bg;
  }

  &--warning {
    color: $color-warning;
    background-color: $color-warning-bg;
  }

  &--error {
    color: $color-error;
    background-color: $color-error-bg;

    &--showDesignVariant {
      background-color: unset;
      color: #e60a3a;
      font-size: 12px;
      padding: 4px;
    }
  }

  &--success {
    color: $color-success;
    background-color: $color-success-bg;
  }

  &__icon-wrapper {
    font-size: $alert-icon-size;
    margin-right: $spacing-4;

    &--showDesignVariant {
      display: none;
    }
  }
}
