@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

.QuestionStep {
  &__image {
    max-width: 185px;
    margin: auto;
    display: block;
  }

  &__wrapper {
    padding: 0 $spacing-5;
    &--showDesignVariant {
      padding: 0;
      @include for-responsive-device-up {
        min-width: 400px;
        padding: 0;
      }
    }
  }

  &__entry {
    margin: $spacing-1 0;

    &--showDesignVariant {
      margin-bottom: $spacing-5;

      > label {
        padding-right: $spacing-5;
      }
    }
  }
}
