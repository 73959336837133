@import '../../sass/variables/';
@import '../../sass/media-queries';

/**
 * @tokens Spacing
 */
$padding-x: $spacing-4;
$padding-y: $spacing-2;
$textarea-padding-new-design: $spacing-5;

/**
 * @tokens BorderRadius
 */
$boder-radius: 4px;
$border-radius-design-variant: 16px;

/**
 * @tokens Border
 */
$border: 1px solid $grey-400;

/**
 * @tokens Color
 */
$color: $grey-900;
$color-design-variant: $grey-900-new-design;
$border-color-design-variant: $grey-900;
$focus-border-color: $brand-primary;
$state-success-border-color: $green-900;
$state-success-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $green-100;
$state-error-border-color: $red-900;
$state-error-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $red-100;
$hover: #888;
$focus-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $brand-primary;
$disabled-background: $grey-100;

/**
 * @tokens FontSize
 */
$input-font-size: 1.1rem;
$input-font-size-design-variant: $font-m;

/**
 * @tokens LineHeight
 */
$textarea-line-height: 1.42857143;
$textarea-line-height-new-design: 20px;

/**
 * @tokens Height
 */
$height: 49px;

.input {
  appearance: none;

  &:read-only {
    background: $grey-100 !important;

    &:focus,
    &:hover {
      border: $border;
      box-shadow: unset;
      outline: none;
    }
  }
}

.input,
.select {
  width: 100%;
  height: $height;
  padding: $spacing-2 $padding-x;
  border-radius: $boder-radius;
  display: block;
  border: $border;
  color: $color;
  box-shadow: none;
  font-size: $input-font-size;
  box-sizing: border-box;

  &:hover {
    border-color: $hover;
  }

  &:focus {
    border-color: $focus-border-color;
    box-shadow: $focus-box-shadow;
    outline: none;
  }

  &:disabled {
    background: $disabled-background;
  }

  &--radio,
  &--checkbox {
    width: initial;
    height: auto;
    appearance: radio;
    display: initial;
    margin-right: 10px;

    &:focus {
      border-color: inherit;
      box-shadow: none;
    }
  }

  &--textarea {
    height: auto;
    display: block;
    font-family: inherit;
    line-height: $textarea-line-height;

    &--showDesignVariant {
      line-height: $textarea-line-height-new-design;
      padding: $textarea-padding-new-design;

      @include for-responsive-device-up {
        width: 606px;
      }
    }
  }

  /**
   * Cleaned rules derived from this ones:
   * https://www.filamentgroup.com/lab/select-css.html
   */
  &--select {
    display: block;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }

    option {
      font-weight: normal;
    }
  }

  &--success {
    border-color: $state-success-border-color;
    box-shadow: $state-success-box-shadow;
  }

  &--error {
    border-color: $state-error-border-color;
    box-shadow: $state-error-box-shadow;
  }

  &--showDesignVariant {
    border-radius: $border-radius-design-variant;
    color: $color-design-variant;
    border-color: $border-color-design-variant;
    font-size: $input-font-size-design-variant;
    box-shadow: unset;

    &:focus {
      border-color: inherit;
      box-shadow: unset;
    }

    &:hover {
      border-color: $border-color-design-variant;
    }

    &--error {
      border-color: #e71f40;
      box-shadow: unset;
    }

    &--success {
      border-color: unset;
      box-shadow: unset;
    }

    &--inputPostalCode {
      padding-left: 35px;
      color: black;
      font-size: 18px;
      font-weight: 500;

      &::placeholder {
        color: $grey-800;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  &--error--showDesignVariant {
    border-color: #e60a3a;
    box-shadow: unset;
  }
}

.postalCodeMapIcon {
  position: absolute;
  left: 10px;
}

.inputPostalCodeContainer__showDesignVariant__placeholder {
  position: relative;
  display: flex;
  align-items: center;
}
