@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

.button-group {
  margin-top: $spacing-7;
  text-align: center;

  @include for-responsive-device {
    font-size: $font-xss;
    display: flex;
    flex-direction: column-reverse;
  }

  :global(.button) {
    &:first-of-type {
      margin-right: $spacing-5;
    }
  }

  &--showDesignVariant {
    @include for-responsive-device {
      position: fixed;
      background-color: white;
      bottom: 0;
      left: 0;
      width: 100vw;
      width: -moz-available;
      width: -webkit-fill-available;
      padding: 16px;
      border-top: 1px solid #d6d6d6;
    }

    @include for-responsive-device-up {
      margin-bottom: 24px;
    }
  }
}
