@import '../../sass/variables/';
@import '@src/ui/sass/media-queries';

/**
 * @tokens Spacing
 */
$padding-xy: 14px 30px;
$padding-design-variant: 16px;

/**
 * @tokens FontSize
 */
$button-font-size: $font-m;

/**
 * @tokens Shadow
 */
$box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.075);

/**
 * @tokens BorderRadius
 */
$border-radius: 25px;
$border-radius-design-variant: 16px;

/**
 * @tokens Border
 */
$border: 1px solid transparent;

/**
 * @tokens Color
 */
$primary-background: $brand-accent;
$primary-background-hover: darken($white, 5%);
$primary-text-color: $white;
$secondary-background: $white;
$secondary-border-color: $grey-100;
$secondary-background-hover: darken($grey-900, 5%);
$secondary-text-color: $grey-900;
$is-loading-icon-color: $grey-700;
$disabled-background: $grey-300;
$disabled-text-color: $grey-700;

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
    opacity: 0.35;
  }

  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: $border;
  user-select: none;
  padding: $padding-xy;
  font-size: $button-font-size;
  box-shadow: $box-shadow;
  line-height: 1;
  border-radius: $border-radius;
  white-space: normal;
  font-family: inherit;

  &[disabled],
  &[disabled]:hover {
    background-color: $disabled-background;
    border-color: $disabled-background;
    color: $disabled-text-color;
    cursor: default;
    box-shadow: none;
  }

  &--primary {
    background-color: $primary-background;
    color: $primary-text-color;
    @include for-responsive-device {
      width: 100%;
    }

    &:hover {
      background-color: $primary-background;
      color: $primary-background-hover;
    }
  }

  &--secondary {
    background-color: $secondary-background;
    color: $secondary-text-color;
    border-color: $secondary-border-color;
    @include for-responsive-device {
      width: fit-content;
      align-self: center;
      margin-top: $spacing-5;
    }

    &:hover {
      background-color: $secondary-background;
      color: $secondary-background-hover;
    }
  }

  &--is-loading {
    position: relative;
    color: $disabled-background;
    display: inline-flex;
    justify-content: center;

    &:before {
      content: '\f283';
      color: $is-loading-icon-color;
      position: absolute;
      font-family: 'iconissimo';
      text-rendering: optimizeLegibility;
      animation: load-animate 2s infinite linear;
    }

    &[disabled],
    &[disabled]:hover {
      color: $disabled-background !important;
    }
  }

  &__icon {
    margin-right: 0.5em;
    vertical-align: baseline;
  }

  &--showDesignVariant {
    border-radius: $border-radius-design-variant;
    padding: $padding-design-variant;

    @include for-responsive-device-up {
      width: 192px;
      height: 48px;
      box-shadow: none;
      font-weight: $font-weight-strong;
    }

    &[disabled],
    &[disabled]:hover {
      color: $primary-text-color;
      font-size: $font-m;
      font-weight: $font-weight-strong;
    }

    &--icon {
      display: none;
      @include for-responsive-device {
        display: block !important;
        background: none !important;
        padding: 0;
        border: none;
        box-shadow: none;
        margin-top: 8px;
        margin-left: 12px;
        margin-bottom: -20px;
      }

      > img {
        @include for-responsive-device-up {
          display: none;
        }
      }
    }
  }
}

:global(.button--PhotoUploadDesignVariant) {
  box-shadow: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  margin: $spacing-5 0 $spacing-5 0;
  line-height: $spacing-5;
  font-size: $font-m;

  @include for-responsive-device-up {
    margin-right: 16px;
    margin-bottom: 0;
  }
}

.showDesignVariant__buttonText {
  @include for-responsive-device {
    display: none;
  }
  font-weight: $font-weight-strong;
}

.button--secondary.button--showDesignVariant {
  @include for-responsive-device {
    display: none;
    border: none;

    &--hidden {
      display: none !important;
    }
  }

  border: 1px solid $grey-900-new-design;
}
