@import '@src/ui/sass/variables';

.buttonLabel {
  cursor: pointer;
  margin-bottom: 5px;
  position: relative;
  background-color: $grey-100;
  min-height: 46px;
  display: flex;
  align-items: center;

  :global(input) {
    margin: 0 $spacing-3 0 $spacing-4;
    outline: 1px solid transparent;
    margin-bottom: 4px;

    &:checked {
      + label {
        background-color: $white;
        outline: 1px solid $border;
      }
    }
  }

  :global(label) {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    cursor: pointer;
    font-weight: $font-weight-regular;
    padding: $spacing-4 $spacing-1;
  }
}

.showDesignVariant {
  background-color: unset;
  border: 1px solid $grey-200-new-design;
  border-radius: $spacing-5;
  margin-bottom: 16px;
  :global(input[type='radio']:checked) {
    appearance: none;
    background-color: $grey-900-new-design !important;
    border: 3px solid white;
    outline: 1px solid $grey-900-new-design;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: 4px;
  }

  :global(input[type='checkbox']:checked) {
    appearance: none;
    background-color: $grey-900-new-design !important;
    border: 2px solid white;
    outline: 1px solid #404040;
    width: 13px;
    height: 13px;
    padding: 0;
  }

  :global(label) {
    margin-bottom: unset;
  }
}

.checkedContainer {
  border: 1px solid black;
}
