@import '../../sass/variables/';
@import '../../sass/media-queries';

.photoUpload {
  text-align: center;
  margin-top: $spacing-4;

  &__optionalText {
    color: $grey-700;
  }

  &__informationImagesAllowed {
    text-decoration: none;
    text-align: left;
    color: $grey-800;
    font-size: $font-m;
  }

  &--showDesignVariant {
    text-align: left;

    @include for-responsive-device-up {
      display: flex;
      align-items: baseline;
    }
  }
}
