@import '../../sass/variables/';

input[type='checkbox'].CheckBox__input {
  margin-right: $spacing-3;
}

label.CheckBox__label {
  font-weight: $font-weight-strong;

  &--showDesignVariant {
    font-size: 14px;

    input[type='checkbox']:checked {
      appearance: none;
      background-color: $grey-900-new-design !important;
      border: 2px solid white;
      outline: 1px solid #404040;
      width: 12px;
      height: 12px;
      padding: 0;
    }
  }
}
