@import '../../sass/variables/';
/**
 * @tokens Color
 */
$link-color-text: $brand-primary;

.link {
  text-decoration: none;
  color: $link-color-text;
  font-family: inherit;

  &:hover,
  &:active {
    outline: 0;
  }

  &--button {
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    color: $link-color-text;
    font-size: 1rem;
    text-decoration: none;
    font-family: inherit;

    &--showDesignVariant {
      color: $grey-900-new-design;
    }

    &--a--showDesignVariant {
      text-decoration: underline;
    }
  }

  &--showDesignVariant {
    text-decoration: underline;
    color: $grey-900-new-design;
  }
}
