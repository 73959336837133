@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

.forwardServiceRequest {
  color: $color-text;
  font-size: $font-l;
  text-align: center;

  &__header {
    margin-bottom: 0;
  }

  &__link {
    color: $brand-primary;
    font-size: $font-m;
    text-decoration: none;
    display: block;
  }

  &__cta {
    margin-top: $spacing-6;
    font-weight: $font-weight-bold;
  }

  &__buttons {
    :global(.button:first-of-type) {
      margin-right: 0;
      margin-top: 16px;

      @include for-responsive-device-up {
        margin-right: $spacing-5;
        margin-top: 0;
      }
    }
  }

  &__button {
    &:nth-of-type(2) {
      margin-top: $spacing-5;

      @include for-responsive-device-up {
        margin-top: 0;
      }
    }
  }
}
