@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

.category {
  &__jobTypes {
    margin-top: $spacing-5;
    margin-bottom: auto;

    &__heading {
      margin: $spacing-6 0 $spacing-3;
      display: block;
      font-weight: $font-weight-strong;
      text-align: center;
      font-size: 20px;
    }

    &__content {
      padding-left: $spacing-5;
      padding-right: $spacing-5;
      &--showDesignVariant {
        padding: 0;
        @include for-responsive-device-up {
          width: 400px;
          margin: 0 auto;
        }
      }
    }
  }
}

.mostPopularSubcategories {
  margin-top: 12px;
  cursor: pointer;
  border: none;
  background: none;
  font: inherit;
  padding: 0;
  text-decoration: underline;

  &--showDesignVariant {
    display: block;
  }
}
