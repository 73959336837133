@import '@src/ui/sass/variables/';

.locationWithSearch {
  max-width: 650px;
  margin: $spacing-6 auto 0;
}

.locationInputSelect {
  max-width: 341px;
  margin: $spacing-6 auto 0;
}
