.mostPopularSubcategoriesHidden {
  display: none;
}

.mostPopularTitle {
  font-weight: 400;
  margin: 24px 0 0 0;
}

.mostPopularSubcategories {
  margin-top: 12px;
  cursor: pointer;
  border: none;
  background: none;
  font: inherit;
  padding: 0;
  text-decoration: underline;

  &--showDesignVariant {
    display: block;
  }
}
