@import '@src/ui/sass/variables';

.HOExperiencePreferenceStep {
  &__image {
    max-width: 150px !important;
    margin: auto;
    display: block;
  }

  &__wrapper {
    padding: 0 $spacing-5;
  }

  &__entry {
    margin: $spacing-1 0;
  }
}
