@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

$remove-button-background: $grey-300;

.descriptionStep {
  max-width: 581px;
  margin: 0 auto;

  &__optional {
    text-align: center;
    color: $color-success;
    font-weight: $font-weight-strong;
  }

  &--showDesignVariant {
    @include for-responsive-device-up {
      max-width: 800px;
    }
  }
}

.thumbnails {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: $spacing-5 0;

  &__item {
    width: 79px;
    height: 79px;
    margin-right: $spacing-2;
    margin-top: $spacing-2;
    position: relative;
    transition: opacity 300ms;

    &--tmp {
      img {
        opacity: 0.4;
        width: 79px;
        height: 79px;
        object-position: center;
      }
    }

    &--showDesignVariant {
      width: 232px;
      height: 144px;
      margin: $spacing-3 $spacing-3 0 0;
    }
  }

  &__spinner {
    position: absolute;
    height: 1em;
    line-height: 1em;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.4em;
    $spinner-icon-size: 25px;
    font-size: $spinner-icon-size;
    color: $grey-900;
    text-shadow: 1px 1px 6px rgba($color: #000000, $alpha: 0.6);
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &__image {
    width: 100%;

    &--showDesignVariant {
      width: inherit;
      height: inherit;
      border-radius: $spacing-5;
      max-width: unset !important;
      max-height: unset !important;
    }
  }

  &__remove {
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: bold;
    color: black;
    background-color: $remove-button-background;
    border: none;
    cursor: pointer;
    padding: $spacing-1 $spacing-2;
    $remove-icon-size: 12px;
    font-size: $remove-icon-size;
    line-height: $remove-icon-size;

    &--showDesignVariant {
      background-color: unset;
      top: $spacing-5;
      right: $spacing-5;
    }
  }

  &--showDesignVariant {
    @include for-responsive-device-up {
      justify-content: center;
    }
  }
}
