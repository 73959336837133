/**
 * @tokens Color
 */
$red-100: #fcbaba;
$red-600: #c78a89;
$red-800: #eb0b40;
$red-900: #a33232;

$grey-100: #ededed;
$grey-200: #edf1f3;
$grey-200-new-design: #d6d6d6;
$grey-300: #d2d2d2;
$grey-400: #cacaca;
$grey-700: #757575;
$grey-800: #666666;
$grey-900: #2b2d2e;
$grey-900-new-design: #404040;

$green-100: #dbf7d7;
$green-400: #30db2b;
$green-900: #0c8a26;

$orange-100: #fce5ba;
$orange-200: #fba762;
$orange-400: #ff7300;
$orange-900: #de6501;

$blue-20-new-design: #ecf3f8;
$blue-100: #d9edf7;
$blue-200: #eefaff;
$blue-200-new-design: #8fb5d6;
$blue-400: #0692ce;
$blue-700: #0058a2;
$blue-900: #31708f;
$white: #ffffff;
$black: #000000;
