@import '../../sass/variables';
@import '../../sass/media-queries';

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: $spacing-2;
  font-weight: $font-weight-strong;

  &--showDesignVariant {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: $spacing-3;

    &--postalCode {
      @include for-responsive-device-up {
        display: none;
      }
    }
  }
}
