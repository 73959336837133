@import '@src/ui/sass/media-queries';

.entering {
  animation: enteringAnimation 0.2s ease-in;

  &--showDesignVariant {
    padding: 0 16px;
    margin-bottom: 80px;

    @include for-responsive-device {
      animation: unset;
    }

    @include for-responsive-device-up {
      max-width: 800px;
      margin: 0 auto;
    }
  }
}

@keyframes enteringAnimation {
  from {
    transform: translateX(40px);
    opacity: 0.2;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
