@import '@src/ui/sass/variables/';

.findAPro {
  flex-direction: column;
  display: flex;

  &__image {
    max-width: 185px;
    margin: auto;
  }

  &__spinner {
    margin-right: $spacing-3;
  }

  &__description {
    margin: auto;
  }
}
