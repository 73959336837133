@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

.serviceRequestForm {
  margin: 0 auto;
  padding: $spacing-5;
  min-height: 369px;
  position: relative;
  color: $color-text;
  font-size: $font-m;

  :global(.modal-body) & {
    min-height: 490px;
  }

  :global(.step-title) {
    text-align: center;
    font-weight: $font-weight-strong;
    margin-top: $spacing-6;

    &--showDesignVariant {
      text-align: left;
      line-height: $spacing-7;
      max-width: 300px;
      -webkit-font-smoothing: antialiased;
      @include for-mobile-l-up {
        text-align: center;
        max-width: none;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  &__form {
    min-height: 284px;
    display: flex;
    flex-direction: column;
  }

  &__free-text {
    text-align: center;
    color: $color-success;
    font-weight: $font-weight-strong;
    margin-top: $spacing-5;
    font-size: $font-l;
    margin-bottom: 0;
  }

  &__third-party,
  &__terms-and-conditions {
    margin-bottom: 10px;
    label {
      font-weight: $font-weight-regular !important;
      margin-bottom: 0;
      @include for-responsive-device {
        font-size: $font-xss;
      }
      a {
        white-space: nowrap;
      }
    }
  }

  &__contact-alert {
    line-height: $default-line-height;
    @include for-responsive-device {
      font-size: $font-xss;
    }

    &--showDesignVariant {
      font-size: 14px;
      font-weight: $font-weight-regular;
      color: $black;
    }
  }

  &__commercial-phone {
    font-size: $font-ml;
    font-weight: normal;
    line-height: $line-height-5;
    @include for-tablet-up {
      width: fit-content;
      margin: auto;
    }
    a {
      color: $black;
      white-space: nowrap;
      text-decoration: none;
    }
  }

  &__commercial-phone-text {
    display: none;
    @include for-tablet-up {
      display: block;
    }
  }

  &__commercial-phone-text-device {
    @include for-tablet-up {
      display: none;
    }
  }

  &__progressBar {
    margin: 0 auto $spacing-7;
    max-width: 350px;
  }

  &__header {
    text-align: center;
    line-height: 1.3;
    margin: $spacing-8 auto;
    max-width: 450px;

    &--showDesignVariant {
      display: none;
    }
  }

  &__confidence-warranty-text {
    font-family: inherit;
    font-style: normal;
    font-size: $font-s;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0;
    text-align: center;
    margin-top: $spacing-5;

    img {
      width: 14.4px !important;
      height: 18.24px !important;
      margin: -3px 7px 0;
    }
    span {
      vertical-align: top;

      span {
        color: $black;
        font-weight: 600;
      }
    }
  }

  &--showDesignVariant {
    display: flex;
    flex-direction: column;
    padding: 0;

    @include for-responsive-device {
      height: auto;
    }
  }
}

.center {
  text-align: center;
  margin-bottom: $spacing-6;
}

.serviceRequestForm__customerContactStep--showDesignVariant {
  @include for-responsive-device-up {
    width: 400px;
  }
}
