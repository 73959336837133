@import '@src/ui/sass/variables';

.rbt-input-hint {
  box-sizing: border-box;
}

.rbt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  overflow: auto !important;
  text-overflow: ellipsis;

  .dropdown-item {
    font-size: 1.1rem;
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 5px 16px;
    box-sizing: border-box;
    text-decoration: none;

    &.active,
    &:hover {
      color: $brand-primary;
      background-color: #ededed;
    }
  }

  .rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
  }
}

.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 12px;
  height: 12px;
  opacity: 0.5;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.sr-only {
  display: none;
}

[id*='--showDesignVariant'] {
  &.rbt-menu {
    &.dropdown-menu {
      border-radius: 1rem;
      margin-top: 8px;
      line-height: 32px;
    }
  }
  &.dropdown-item {
    font-size: 14px;
    color: $grey-900-new-design;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .rbt-highlight-text {
      font-weight: $font-weight-strong;
      color: $grey-900-new-design;
    }

    &.active,
    &:hover {
      color: unset;
      background-color: #ededed;
    }
  }
}
