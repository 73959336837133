@import '@src/ui/sass/variables/';
@import '@src/ui/sass/media-queries';

.jobTypeAutocomplete {
  &__category {
    color: $grey-700;
    display: none;

    @include for-tablet-up {
      display: inline-block;
    }

    &--showDesignVariant {
      display: flex;
      align-items: center;
      border-radius: 4px;
      background-color: #f2f2f2;
      padding: 4px 8px;
      margin-left: 16px;
      height: fit-content;
      width: fit-content;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: $grey-900-new-design;
      line-height: normal;
    }
  }
}
