@import '../../sass/variables/';
@import '../../sass/media-queries';

.ProgressBar {
  background-color: #ededed;
  border-radius: 8px;
  overflow: hidden;

  &__inner {
    height: 12px;
    background-color: $brand-primary;
    transition: width 0.5s;
    border-radius: 8px;

    &--showDesignVariant {
      background-color: $white;
    }
  }

  &--showDesignVariant {
    background-color: $blue-200-new-design;
    border-radius: 32px;
    margin: 0 16px;

    @include for-responsive-device-up {
      max-width: 343px;
      margin: 0 auto;
    }
  }

  &__NewDesignProgressBar__progressText {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: $font-weight-strong;
    color: $white;

    @include for-responsive-device-up {
      font-size: 14px;
    }
  }
}
