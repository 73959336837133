@import '../../sass/variables/';
@import '../../sass/media-queries';

.NewDesignProgressBar {
  @include for-mobile-to-tablet {
    background: url('static/mediumBackgroundNewProgressBar.png') round;
  }
  @include for-tablet-up {
    background: url('static/bigBackgroundNewProgressBar.png') round;
  }
  background: url('static/backgroundNewProgressBar.png') round;

  &__title {
    width: 342px;
    margin: 20px auto 0;
    font-size: 18px;
    line-height: 26px;
    font-weight: $font-weight-strong;
    text-align: center;
    color: $white;

    @include for-responsive-device-up {
      font-size: 24px;
    }
  }

  &__body {
    width: 342px;
    margin: 8px auto 16px;
    font-size: 14px;
    line-height: 18px;
    font-weight: $font-weight-regular;
    text-align: center;
    color: $white;

    @include for-responsive-device-up {
      width: 800px;
      font-size: 16px;
    }
  }

  &__logo {
    display: none;
    @include for-responsive-device-up {
      display: flex;
      position: absolute;
      left: 32px;
      top: 16px;
      width: 192px;
      height: 27px;
    }
  }
}
