/*
 * Simplified media queries with a mobile-first perspective:
 *  - default styles target smallest screen possible
 *  - add special behaviors for screens bigger than a threshold
 *  - if you need a very special case just use a custom media-query where you need it, leave this for the truly generic ones
 */

/*
 * Uncomment mixins as required, maybe update the breakpoints as this comes from
 * a project which uses different breakpoints

  @mixin for-desktop-up {
   @media (min-width: 1400px) {
     @content;
   }
  }

  @mixin for-laptop-up {
   @media (min-width: 1024px) {
     @content;
   }
  }
*/

@mixin for-mobile-l-up {
  @media (min-width: 425px) {
    @content;
  }
}

@mixin for-tablet-up {
  @media (min-width: 740px) {
    @content;
  }
}

@mixin for-responsive-device {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin for-responsive-device-up {
  @media (min-width: 993px) {
    @content;
  }
}

@mixin for-mobile-to-tablet {
  @media (min-width: 425px) and (max-width: 739px) {
    @content;
  }
}
